



























































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';
import queryString from 'query-string';

import { constants } from '@/utils/constants';

import i18n from '@/i18n';
import ManageProductCatalogModal from './ManageProductCatalogModal.vue';

import { getAvailableProducts } from './product-catalog-api';

@Component({
  components: {
    ManageProductCatalogModal,
  },
})
export default class extends Vue {
  items: Array<any> = [];

  tableBusy = false;
  status: any|null = null;
  productType: any|null = null;
  name: any|null = null;
  allItemsCount = 0;

  itemsPerPage = 15;
  currentGridPage = 1;
  editItemsPerPage = this.itemsPerPage;

  selectedInvetoryOrderCriteriaName = constants.inventoryOrderCriteria.denumire.name;
  inventoryOrderCriteriaValue = constants.inventoryOrderCriteria.denumire.value;

  sessionStorage = window.sessionStorage;
  localStorage = window.localStorage;

  get fields() {
    return [
      {
        key: 'productCode',
        label: 'Cod',
        tdClass: 'col-2',
      },
      {
        key: 'name',
        label: 'Denumire',
        tdClass: 'col-2',
      },
      {
        key: 'measurementUnit',
        label: 'UM',
        tdClass: 'col-1',
      },
      {
        key: 'isService',
        label: 'Tip',
        tdClass: 'col-1',
      },
      {
        key: 'vatPercentage',
        label: '% TVA',
        tdClass: 'col-1 text-center',
        thClass: 'text-center',
      },
      {
        key: 'price',
        label: 'Pret',
        tdClass: 'col-1 text-right',
        thClass: 'text-center',
      },
      {
        key: 'classification',
        label: 'Clasificare',
        tdClass: 'col-1',
      },
      {
        key: 'isActive',
        label: 'Stare',
        tdClass: 'col-1',
      },
      {
        key: 'actiuni',
        label: i18n.t('catalogManagement.productCatalog.gridActions'),
        thClass: 'col-action',
        tdClass: 'col-action-body',
      },
    ];
  }

  statusOptions = [
    { status: false, label: 'Inactiv' },
    { status: true, label: 'Activ' },
  ];

  productTypeOptions = [
    { type: false, label: 'Produs' },
    { type: true, label: 'Serviciu' },
  ];

  inventoryOrderCriteriaOptions = [
    constants.inventoryOrderCriteria.denumire.name,
    constants.inventoryOrderCriteria.codProdus.name,
  ];

  addToGRNSessionStorage(key, value) {
    sessionStorage.setItem('prod_grid_session_'.concat(key), value);
  }

  getFromGRNSessionStorage(key) {
    return sessionStorage.getItem('prod_grid_session_'.concat(key)) || '';
  }

  addToGRNLocalStorage(key, value) {
    sessionStorage.setItem('prod_grid_local_'.concat(key), value);
  }

  getFromGRNLocalStorage(key) {
    return sessionStorage.getItem('prod_grid_local_'.concat(key)) || '';
  }

  onAdd() {
    (<any>(this.$refs.addEditModal)).showModal();
  }

  showDetails(productCode) {
    (<any>(this.$refs.addEditModal)).showModal(productCode, constants.productCatalogModalState.view);
  }

  showAddModal() {
    (<any>(this.$refs.addEditModal)).showModal(null, constants.productCatalogModalState.add);
  }

  async onAddEditModalOk() {
    await this.loadGridPage();
  }

  onSelectInventoryOrderCriteria(name) {
    switch (name) {
      case constants.inventoryOrderCriteria.codProdus.name:
        this.inventoryOrderCriteriaValue = constants.inventoryOrderCriteria.codProdus.value;
        break;
      default:
        this.inventoryOrderCriteriaValue = constants.inventoryOrderCriteria.denumire.value;
        break;
    }

    this.selectedInvetoryOrderCriteriaName = name;
  }

  async onCurrentGridPageChanged(page) {
    this.currentGridPage = page;
    await this.loadGridPage();
  }

  async onApplyFilters() {
    this.itemsPerPage = this.editItemsPerPage;
    this.storeFilters();
    await this.loadGridPage();
  }

  async loadGridPage() {
    try {
      this.tableBusy = true;

      const listResult = camelcaseKeys(await getAvailableProducts(this.name ? this.name : '',
      this.productType?.type,
      this.status?.status,
      this.currentGridPage,
      this.itemsPerPage,
      this.inventoryOrderCriteriaValue));

      this.allItemsCount = listResult.count;
      this.items = listResult.items;
    } catch (err) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    } finally {
      this.tableBusy = false;
    }
  }

  storeFilters() {
    this.addToGRNSessionStorage('name', this.name);
    this.addToGRNSessionStorage('type', this.productType?.type);
    this.addToGRNSessionStorage('orderBy', this.inventoryOrderCriteriaValue);
    this.addToGRNSessionStorage('status', this.status?.status);

    this.addToGRNLocalStorage('itemsPerPage', this.itemsPerPage);
  }

  async initialize() {
    await this.loadGridPage();
  }

  async onExcelExport() {
    const query = queryString.stringify({
      lookupTerm: this.name,
      isService: this.productType?.type,
      inventoryOrderCriteria: this.inventoryOrderCriteriaValue,
      productStatus: this.status?.status,
    });
    window.location.href = `/api/adm/product-catalog/excel-export?${query}`;
  }

  setOrderCriteriaName(value) {
    switch (value) {
      case constants.inventoryOrderCriteria.codProdus.value:
        this.selectedInvetoryOrderCriteriaName = constants.inventoryOrderCriteria.codProdus.name;
        break;
      default:
        this.selectedInvetoryOrderCriteriaName = constants.inventoryOrderCriteria.denumire.name;
        break;
    }
  }

  getProductGridType(type) {
    return this.productTypeOptions.filter((option) => option.type.toString() === type);
  }

  getProductGridStatus(type) {
    return this.statusOptions.filter((option) => option.status.toString() === type);
  }

  created() {
    this.name = this.getFromGRNSessionStorage('name');
    if (this.getFromGRNSessionStorage('type')) {
      [this.productType] = this.getProductGridType(this.getFromGRNSessionStorage('type'));
    }
    this.inventoryOrderCriteriaValue = parseInt(this.getFromGRNSessionStorage('orderBy'), 10);
    this.setOrderCriteriaName(this.inventoryOrderCriteriaValue);
    if (this.getFromGRNSessionStorage('status')) {
      [this.status] = this.getProductGridStatus(this.getFromGRNSessionStorage('status'));
    }

    if (this.getFromGRNLocalStorage('itemsPerPage')) {
      this.itemsPerPage = parseInt(this.getFromGRNLocalStorage('itemsPerPage'), 10);
      this.editItemsPerPage = this.itemsPerPage;
    }

    this.initialize();
  }
}
